<template>
  <!--begin::List Widget 9-->
  <div class="">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-1">
      <h3 class="card-title align-items-start flex-column">
        <span class="font-weight-bolder text-dark">
          Actividad de Notificaciones
        </span>
        <br>
        <span class="text-muted mt-1 font-weight-bold font-size-sm">
          {{notificationsList.length}} Notificaciones
        </span>
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-0">
      <div class="timeline timeline-6 mt-1">
        <template v-for="(item, i) in notificationsList">
          <!--begin::Item-->
          <div class="timeline-item align-items-start" v-bind:key="i">
            <!--begin::Label-->
            <div
              class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
            >
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="icon-xl" v-bind:class="item.status == 1 ? 'fa fa-genderless text-success' : 'fa fa-genderless text-danger'"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div
              class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
            >
              <span
                v-bind:class="{
                  'font-weight-bolder text-dark-75': item.bold,
                  'mr-4': item.images
                }"
                v-html="item.created_at.substring(0,16)+'  |  '+item.subject"
              ></span>
              <span
                v-bind:class="{
                  'font-weight-bolder text-dark-70': item.bold,
                }"
              > | Id: {{item.id}}</span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </template>
      </div>
      <!--end: Items-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Card-->
  <!--end: List Widget 9-->
</template>

<script>
import { mapGetters } from "vuex";
import { GET_NOTIFICATIONS } from "@/core/services/store/cvtas.module";

export default {
  name: "widget-9",
  data() {
    return {
      notificationsList: [],
      list: [
        {
          time: "08:42",
          desc: "Calor inesperado en Managua, Nicaragua",
          badge: "fa fa-genderless text-success"
        },
        {
          time: "10:00",
          desc: "Altos niveles de lluvia en San José, Costa Rica",
          bold: true,
          badge: "fa fa-genderless text-danger"
        },
        {
          time: "14:37",
          desc: `Alta velocidad de viento en Ciudad de Panamá, Panamá`,
          badge: "fa fa-genderless text-info",
          bold: true
        },
        {
          time: "16:50",
          desc: "Frio en Quetzaltenango, Guatemala",
          badge: "fa fa-genderless text-danger"
        },
        {
          time: "17:30",
          desc: "Baja visibilidad en Tegucigalpa, Honduras",
          badge: "fa fa-genderless text-success",
          bold: true
        },
        {
          time: "21:03",
          desc: `Categoria de Vuelo en Goldson Intl, Belize.`,
          badge: "fa fa-genderless text-warning"
        },
      ]
    };
  },
  components: {
  },
  methods: {
    lastElement(i) {
      if (i === this.list.length - 1) {
        return false;
      } else {
        return true;
      }
    },
    fetchNotifications(){
      this.$store.dispatch(GET_NOTIFICATIONS, this.currentUser.email).then((response) => {
          // console.log('notificaciones : '+response);
          // context.commit(SET_ALERTS, JSON.parse(response).alert);
          this.notificationsList = JSON.parse(response).notification;
      }).catch((err) => {console.log(err)});
    }
  },
  beforeMount() {
    this.fetchNotifications();
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["currentUser"]),
  }
};
</script>
