<template>
  <div>
    <div class="row px-3 pt-3">
      <div class="col-md-12">
        <v-card class="pb-3" v-if="false">
            <v-card-title>
                Preferencias de Notificaciones
            <v-spacer></v-spacer>
            </v-card-title>
            <b-container fluid="sm" style="font-size: 15px;">
              <b-form-group label="Medio de Notificación" v-slot="{ notificationPreferences }">
                <b-form-radio-group
                  id="notificationPreferences"
                  v-model="selectedNotif"
                  :options="notifOptions"
                  :aria-describedby="notificationPreferences"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-container>
        </v-card>
      </div>
    </div>
    <div class="row px-3">
      <div class="col-md-12">
        <v-card>
            <v-card-title>
                Alertas y Avisos
                <button
                  v-b-modal.alert-modal
                  class="btn btn-elevate btn-primary font-weight-bold ml-6 font-size-3"
                >
                  <v-icon
                    right
                    dark
                  >
                    mdi-bell-plus-outline
                  </v-icon>
                  Agregar Alerta
                </button>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="code4.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
            ></v-text-field>
         
             
            </v-card-title>
            <v-data-table
            :headers="code4.headers"
            :items="alertsList"
            :search="code4.search"
            class="elevation-1"
            sort-by="id"
            :sort-desc="true"
            @click:row="handleClick"
            >
              <template v-slot:item.alert_source_id="{ item }">
                <p>
                  {{item.alert_source_id == 5 ? "Estación" : "Pronóstico"}}  
                </p>
              </template>
              <template v-slot:item.location="{ item }">
                <p>
                  {{item.location+(item.name ? ' | '+item.name : '')}}  
                </p>
              </template>
              <template v-slot:item.condition="{ item }">
                <p v-if="item.condition == 'GT'"><v-icon >mdi-page-last</v-icon>Mayor que</p>
                <p v-if="item.condition == 'LT'"><v-icon >mdi-page-first</v-icon>Menor que</p>
              </template>
              <template v-slot:item.variable_id="{ item }">
                <p v-if="item.variable_id">{{getVariableName(item.variable_id)}}</p>
                <p v-else-if="item.fvariable">
                  {{item.fvariable}}
                </p>
              </template>
              <template v-slot:item.status="{ item }">
                <p>
                  {{item.status == 1 ? "Activo" : "Inactiva"}}  
                </p>
              </template>
            </v-data-table>
        </v-card>
      </div>
    </div>
    <div class="row px-3 pt-3">
      <div class="col-md-12">
        <v-card class="pb-3">
            <v-card-title>
                Historial de Notificaciones
            <v-spacer></v-spacer>
                      
            </v-card-title>
            <b-container fluid="lg">
              <NotifLog></NotifLog>
            </b-container>
        </v-card>
      </div>
    </div>
    <!-- modal -->
    <b-modal id="alert-modal" :title="!editing ? 'Agregar Alerta' : 'Editar Alerta'" 
      modal-cancel="Cancelar" modal-ok="Guardar" @ok="handleAlertSubmit" @hide="handleHide">
      <template #modal-ok>
        {{editing ? 'Editar' : 'Crear Alerta'}}
      </template>
      <template #modal-cancel>
       Cancelar
      </template>
      <b-container class="m-0 p-0">
          <b-row align-h="center" class="">
            <b-col align-self="center" cols="12">
              <h3 class="mt-1">Tipo de Alerta</h3>
            </b-col>
          </b-row> 
          <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="12" class="mb-0">
                <b-form-group label="Seleccione el tipo de alerta" >
                  <b-form-radio inline v-model="alertType" name="some-radios" value="estacion">Estación</b-form-radio>
                  <b-form-radio inline v-model="alertType" name="some-radios" value="pronostico">Pronóstico</b-form-radio>
                </b-form-group>
              </b-col>
            </b-row> 
          <div v-if="alertType == 'pronostico'">
            <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="12">
                <p class="my-0">Lugar</p>
              </b-col>
            </b-row> 
            <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="4">
                <button
                    @click="addLugar"
                    class="btn btn-elevate btn-primary font-weight-bold"
                  >
                    <v-icon right dark >
                      mdi-map-plus
                    </v-icon>
                    Agregar
                  </button>
              </b-col>
              <b-col align-self="center" cols="8">
                <b-form-input v-model="alertData.location" placeholder="<- Selecciona un lugar" type="text" readonly></b-form-input>
              </b-col>
            </b-row>
            <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="6">
                <b-row align-h="center" class="">
                  <b-col align-self="center" cols="12">
                    <p class="my-0">Latitud</p>
                  </b-col>
                </b-row> 
                <b-row align-h="center" class="mt-0">
                  <b-col align-self="center" cols="12">
                      <b-form-input v-model="alertData.lat" placeholder="Selecciona un lugar" type="number" readonly></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
              <b-col align-self="center" cols="6">
                  <b-row align-h="center" class="">
                    <b-col align-self="center" cols="12">
                      <p class="my-0">Longitud</p>
                    </b-col>
                  </b-row> 
                  <b-row align-h="center" class="mt-0">
                    <b-col align-self="center" cols="12">
                      <b-form-input v-model="alertData.lon" placeholder="Selecciona un lugar" type="number" readonly></b-form-input>
                    </b-col>
                  </b-row>
              </b-col>
            </b-row>
            <b-row align-h="center" class="">
              <b-col align-self="center" cols="12">
                <p class="my-0">Nombre</p>
              </b-col>
            </b-row> 
            <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="12">
                <b-form-input v-model="alertData.name" type="text"></b-form-input>
              </b-col>
            </b-row>
            <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="12">
                  <b-row align-h="center" class="">
                    <b-col align-self="center" cols="12">
                      <p class="my-0">Parámetro</p>
                    </b-col>
                  </b-row> 
                  <b-row align-h="center" class="mt-0">
                    <b-col align-self="center" cols="12">
                      <v-select :options="fVariablesList" label="fvariable_name" 
                        :reduce="option => option.fvariable" v-model="alertData.parameter"
                        >
                        <template v-slot:option="option">
                          {{`${option.fvariable} | ${option.fvariable_name}`}}
                        </template>
                      </v-select>
                      <!-- <b-form-select v-model="alertData.parameter" :options="fVariablesList"></b-form-select> -->
                    </b-col>
                  </b-row>
              </b-col>
            </b-row>
            <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="6">
                <b-row align-h="center" class="">
                  <b-col align-self="center" cols="12">
                    <p class="my-0">Condición</p>
                  </b-col>
                </b-row> 
                <b-row align-h="center" class="mt-0">
                  <b-col align-self="center" cols="12">
                    <b-form-select v-model="alertData.condition" :options="conditionOptions"></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col align-self="center" cols="6">
                  <b-row align-h="center" class="">
                    <b-col align-self="center" cols="12">
                      <p class="my-0">Valor</p>
                    </b-col>
                  </b-row> 
                  <b-row align-h="center" class="mt-0">
                    <b-col align-self="center" cols="12">
                      <b-form-input v-model="alertData.value" type="number"></b-form-input>
                    </b-col>
                  </b-row>
              </b-col>
            </b-row>
            <b-row align-h="center" class="">
              <b-col align-self="center" cols="12">
                <p class="my-0">Preferencia de Notificaciones</p>
              </b-col>
            </b-row> 
            <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="12">
                <b-container fluid="sm" style="font-size: 15px;">
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="selectedNotif"
                      :options="notifOptions"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options"
                    ></b-form-radio-group>
                  </b-form-group>
                </b-container>
              </b-col>
            </b-row>
            <b-row v-if="invalidAlert" align-h="center" class="">
              <b-col align-self="center" cols="12">
                <p class="my-0 text-danger">Por favor completa todos los campos para agregar una alerta.</p>
              </b-col>
            </b-row> 
          </div>
          <div v-if="alertType == 'estacion'">
              <div>
                <h5>Estación</h5>
                <span v-if="editing" class="mb-2">Estación Actual: {{currentStationValue}}</span>
                <v-select :options="stationsList" label="station_name" 
                  :reduce="option => option" v-model="newAlertEstacion"
                  :filter-by="myCustomFilter"
                  >
                  <template v-slot:option="option">
                    {{`${option.station_name} | Id: ${option.source_station_id}`}}
                  </template>
                </v-select>
                <!-- <input list="estaciones-list" v-model="newAlertEstacion">
                  <datalist id="estaciones-list">
                    <option v-for="estacion in stationsList" :value="estacion">
                      {{`${estacion.station_name} | Id: ${estacion.source_station_id}`}}
                    </option>
                  </datalist>
                </input> -->
              </div>
              <div class="mt-2">
                <h5>Variable</h5>
                <p v-if="editing">Variable Actual: {{getVariableName(currentVariableValue)}} </p>
                <v-select :options="variablesList" label="description" :reduce="option => option.id" v-model="newAlertVariable">
                  <template v-slot:option="option">
                    {{`${option.variable_name} | ${option.description}`}}
                  </template>
                </v-select>
                <!-- <b-form-input list="variables-list"></b-form-input>
                <datalist id="variables-list">
                  <option v-for="vari in variablesList">
                    {{vari.description}}
                  </option>
                </datalist> -->
              </div>
              <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="6">
                <b-row align-h="center" class="">
                  <b-col align-self="center" cols="12">
                    <h5 class="my-0">Condición</h5>
                  </b-col>
                </b-row> 
                <b-row align-h="center" class="mt-0">
                  <b-col align-self="center" cols="12">
                    <b-form-select v-model="alertData.condition" :options="conditionOptions"></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col align-self="center" cols="6">
                  <b-row align-h="center" class="">
                    <b-col align-self="center" cols="12">
                      <h5 class="my-0">Valor</h5>
                    </b-col>
                  </b-row> 
                  <b-row align-h="center" class="mt-0">
                    <b-col align-self="center" cols="12">
                      <b-form-input v-model="alertData.value" type="number"></b-form-input>
                    </b-col>
                  </b-row>
              </b-col>
              </b-row>   
              <b-row align-h="center" class="">
              <b-col align-self="center" cols="12">
                <h6 class="my-0">Preferencia de Notificaciones</h6>
              </b-col>
            </b-row> 
            <b-row align-h="center" class="mt-0">
              <b-col align-self="center" cols="12">
                <b-container fluid="sm" style="font-size: 15px;">
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="selectedNotif"
                      :options="notifOptions"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options"
                    ></b-form-radio-group>
                  </b-form-group>
                </b-container>
              </b-col>
            </b-row>          
          </div>
      </b-container>
    </b-modal>
    <b-modal id="lugar-modal" size="xl" class="m-0 p-0" title="Agregar Lugar" hide-footer>
      <b-container class="m-0 p-0">
          <iframe style=" width: 100%; height: 80vh;" src="/maps/directions.html" frameborder="0"></iframe>    
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NotifLog from "@/view/content/widgets/list/NotificationsLog.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import { ModelSelect, ModelListSelect } from 'vue-search-select'
import { GET_ALERTS, ADD_ALERT, EDIT_ALERT, GET_STATIONS, GET_VARIABLES, GET_ALL_VARIABLES, GET_NOTIFICATIONS } from "@/core/services/store/cvtas.module";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';


export default {
  data() {
    return {
      myCustomFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return option.station_name.toLowerCase().indexOf(temp) > -1 || 
      option.source_station_id.toLowerCase().indexOf(temp) > -1
      },
      // 
      currentVariableValue: null,
      currentStationValue: null,
      // 
      editing: false,
      currentEditId: null,
      variablesList: [],
      fVariablesList: [],
      stationsList: [],
      notificationsList: [], 
      objectItem: {},
      alertType: null,
      alertsList: [],
      invalidAlert: false,
      newAlertEstacion: {
        latitude: null,
        longitude: null,
        station_name: null,
        id: null,
      },
      newAlertEstacionClean: {
        latitude: null,
        longitude: null,
        station_name: null,
        id: null,
      },
      newAlertVariable: null,
      alertData : {
        location: null,
        lat: null,
        lon: null,
        name: null,
        parameter: null,
        condition: null,
        value: null,
        notification_preferences: true
      },
      alertDataClear : {
        location: null,
        lat: null,
        lon: null,
        name: null,
        parameter: null,
        condition: null,
        value: null,
        notification_preferences: true
      },
      selected: null,
      selected2: null,
      selected3: null,
      selectedNotif: 1,
      value: null,
      value2: null,
      options1: [
          { value: 'CR', text: 'Costa Rica' },
          { value: 'EL', text: 'El Salvador' },
          { value: 'GT', text: 'Guatemala' },
          { value: 'HN', text: 'Honduras' },
          { value: 'NI', text: 'Nicaragua' },
          { value: 'HN', text: 'Belice' },
          { value: 'NI', text: 'Panama' }
      ],
      options2: [
          { value: 'Lluvia de 24h', text: 'Lluvia de 24h' },
          { value: 'Temperatura Mínima', text: 'Temperatura Mínima' },
          { value: 'Temperatura Máxima', text: 'Temperatura Máxima' },
          { value: 'Velocidad máxima de viento', text: 'Velocidad máxima de viento' }  
      ],
      conditionOptions: [
          { value: 'GT', text: 'Es mayor a' },
          { value: 'LT', text: 'Es menor a' }
      ],
      notifOptions: [
          { text: 'Notificar', value: 1 },
          { text: 'No Notificar', value: 0 },

      ],
      code4: {
        search: "",
        headers: [
          {
            text: "Id",
            align: "left",
            sortable: false,
            value: "id"
          },
          { text: "Tipo", value: "alert_source_id" },
          { text: "Ubicación", value: "location" },
          { text: "Lat", value: "lat" },
          { text: "Lon", value: "lon" },
          { text: "Parámetro", value: "variable_id" },
          { text: "Condición", value: "condition" },
          { text: "Valor", value: "value" },
          { text: "Creado en", value: "created_at" },
          { text: "Estado", value: "status" },
        ]
      }
    };
  },
  components: {
    NotifLog,
    ModelSelect,
    ModelListSelect,
    vSelect
  },
  methods: {
    handleHide(){
      this.editing = false;
      this.currentEditId = null;
      this.cleanAlert();
    },
    handleClick(row){
      console.log(row);
      this.editing = true;
      this.currentEditId = row.id;
      this.currentStationValue = row.location;
      if(row.alert_source_id == "5"){
        this.currentVariableValue = row.variable_id;
        this.alertType = "estacion";
        // this.newAlertEstacion.latitude = row.lat;
        // this.newAlertEstacion.longitude = row.lon;
        // this.newAlertEstacion.station_name = row.location;
        // this.newAlertVariable = row.variable_id;
        this.alertData.value = row.value;
        // this.newAlertEstacion.id = this.stationsList.filter(function(o) {
        //   return o.id === row.station_id;
        // });
        this.selectedNotif = row.status
        this.alertData.condition = row.condition;
      } else {
        this.alertType = "pronostico";
        this.alertData.lat = row.lat;
        this.alertData.lon = row.lon;
        this.alertData.location = row.location;
        this.alertData.parameter = row.fvariable;
        this.alertData.value = row.value;
        this.selectedNotif = row.status; 
        this.alertData.condition = row.confition;
        this.alertData.name = row.name;
      }
      setTimeout(() => {
        this.$bvModal.show('alert-modal');
      }, 100);
      
    },
    getVariableName(id){
      try {
        let result = this.allVariablesList.find(obj => {
          return obj.id == id
        });
        return result.variable_name;
      } catch {
        console.log('undefined: ',this.allVariablesList)
      }
     
    },
    addLugar(){
      //  window.open('/maps/directions.html', '_blank').focus();
      this.$bvModal.show('lugar-modal')	
    },
    setLocation (event) {
      console.log(event);
      this.alertData.location = event.data.address;
      this.alertData.name = event.data.address;
      this.alertData.lat = event.data.lat;
      this.alertData.lon = event.data.lon;
      this.$bvModal.hide('lugar-modal');
    },
    handleAlertSubmit(bvModalEvt){
      console.log('alert submit');
      // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        if(
            // this.alertData.location && this.alertData.name && this.alertData.lat &&
            // this.alertData.lon && this.alertData.parameter && this.alertData.condition &&
            // this.alertData.value
            true
          ){
            this.invalidAlert = false;
            if(this.editing){
              this.editAlert()
            } else {
              this.addAlert()
            }
            
        } else {
          this.invalidAlert = true;
        } 
    },
    editAlert(){
      let payload = {};
      payload.id = this.currentEditId;
      if(this.alertType == "estacion"){
        payload.alert_source_id = 5;
        payload.lat = this.newAlertEstacion.latitude;
        payload.lon = this.newAlertEstacion.longitude;
        payload.location =  this.newAlertEstacion.station_name;
        payload.variable_id = this.newAlertVariable;
        payload.value = this.alertData.value;
        payload.station_id =  this.newAlertEstacion.id;
        payload.status = this.selectedNotif;
        payload.condition = this.alertData.condition;
        payload.msg_group = "GT";
        payload.user_id = this.currentUser.email;
      } else {
        payload.alert_source_id = 6;
        payload.lat = this.alertData.lat;
        payload.lon = this.alertData.lon;
        payload.location =  this.alertData.location;
        payload.fvariable = this.alertData.parameter;
        payload.value = this.alertData.value;
        payload.status = this.selectedNotif;
        payload.condition = this.alertData.condition;
        payload.msg_group = "GT";
        payload.user_id = this.currentUser.email;
        payload.name = this.alertData.name;
      }
     

      console.log(payload);

      this.$store
        .dispatch(EDIT_ALERT, payload)
        .then((response) =>{
          response = JSON.parse(response);
          console.log('RESPONSE: ', response);
          this.fetchAlerts();
          if(response.success){
            this.cleanAlert();
            this.$bvModal.hide('alert-modal');
          }
        }).catch(() => {});
    },
    addAlert(){
      let payload = {};
      if(this.alertType == "estacion"){
        payload.alert_source_id = 5;
        payload.lat = this.newAlertEstacion.latitude;
        payload.lon = this.newAlertEstacion.longitude;
        payload.location =  this.newAlertEstacion.station_name;
        payload.variable_id = this.newAlertVariable;
        payload.value = this.alertData.value;
        payload.station_id =  this.newAlertEstacion.id;
        payload.status = this.selectedNotif;
        payload.condition = this.alertData.condition;
        payload.msg_group = "GT";
        payload.user_id = this.currentUser.email;
      } else {
        payload.alert_source_id = 6;
        payload.lat = this.alertData.lat;
        payload.lon = this.alertData.lon;
        payload.location =  this.alertData.location;
        payload.fvariable = this.alertData.parameter;
        payload.value = this.alertData.value;
        payload.status = this.selectedNotif;
        payload.condition = this.alertData.condition;
        payload.msg_group = "GT";
        payload.user_id = this.currentUser.email;
        payload.name = this.alertData.name;
      }
     

      console.log(payload);

      this.$store
          .dispatch(ADD_ALERT, payload)
          .then((response) =>{
            response = JSON.parse(response);
            console.log('RESPONSE: ', response);
            this.fetchAlerts();
            if(response.success){
              this.cleanAlert();
              this.$bvModal.hide('alert-modal');
            }
          }).catch(() => {});
    },
    cleanAlert(){
      this.newAlertEstacion = this.newAlertEstacionClean;
      this.alertType = null;
      this.newAlertEstacion.latitude = null;
      this.newAlertEstacion.longitude = null;
      this.newAlertVariable = null;
      this.alertData.condition = null;
      this.alertData.value = null;
      this.selectedNotif = 1;
      this.alertData = this.alertDataClear;
    },
    addAlert2(){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({...this.alertData, ...{'uid': this.currentUser.uid}});

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://cvtas-api.herokuapp.com/alert", requestOptions)
        .then(response => response.text())
        .then(result => {
            this.$bvModal.hide('alert-modal');
            this.alertData = this.alertDataClear;
            console.log(result);
            this.fetchAlerts();
          }
        )
        .catch(error => console.log('error', error));
    },
    fetchAlerts(){
      this.$store.dispatch(GET_ALERTS).then((response) => {
          // console.log('alerts: '+response);
          // context.commit(SET_ALERTS, JSON.parse(response).alert);
          this.alertsList = JSON.parse(response).alert;
      }).catch((err) => {console.log(err)});
    },
    fetchAllVariables(){
      this.$store.dispatch(GET_ALL_VARIABLES).then((response) => {
          // console.log('variables: '+response);
          // context.commit(SET_ALERTS, JSON.parse(response).alert);
          this.allVariablesList = JSON.parse(response).variable;
      }).catch((err) => {console.log(err)});
    },
    fetchStations(){
      this.$store.dispatch(GET_STATIONS).then((response) => {
          console.log('Estaciones : '+response);
          // context.commit(SET_ALERTS, JSON.parse(response).alert);
          this.stationsList = JSON.parse(response).all_stations;
      }).catch((err) => {console.log(err)});
    },
    fetchNotifications(){
      this.$store.dispatch(GET_NOTIFICATIONS, this.currentUser.email).then((response) => {
          // console.log('notificaciones : '+response);
          // context.commit(SET_ALERTS, JSON.parse(response).alert);
          this.notificationsList = JSON.parse(response).notification;
      }).catch((err) => {console.log(err)});
    },
    fetchVariables(station_id){
      this.$store.dispatch(GET_VARIABLES, "https://927d1d30.us-south.apigw.appdomain.cloud/climahub-cvtas/_api/cvtas_api.php?op=stn_vars&station_id="+station_id)
      .then((response) => {
          console.log('variables', response)
          let tempVariablesList = JSON.parse(response);
          this.variablesList = tempVariablesList.filter(function(el){
            return el.variable_name !== 'PCA';
          });

      }).catch((err) => {console.log(err)});
    },
    fetchForecastVars(){
      this.$store.dispatch(GET_VARIABLES, "https://927d1d30.us-south.apigw.appdomain.cloud/climahub-cvtas/_api/cvtas_api.php?op=fcst_vars")
      .then((response) => {
          console.log('variables forecast: ', response)
          this.fVariablesList = JSON.parse(response);
      }).catch((err) => {console.log(err)});
    },
    fetchAlerts2(){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "uid": this.currentUser.uid
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://cvtas-api.herokuapp.com/alerts", requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result)
          console.log(result);
          this.alertsList = Object.entries(result.alerts).map(key => { return key[1]; });
          console.log(this.alertsList);
        })
        .catch(error => console.log('error', error));
    },
    fetchDataLastGrouped(){

    let config = {
      method: 'get',
      url: 'https://927d1d30.us-south.apigw.appdomain.cloud/climahub-cvtas/_api/estaciones_grouped.php',
      headers: { 
        'Access-Control-Allow-Origin': '*', 
        'Content-Type': 'application/json', 
        'Access-Control-Allow-Credentials': 'true', 
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Access-Control-Allow-Headers, Accept, Origin, Authorization, X-Authorization', 
        'Connection': 'keep-alive', 
        'Cookie': 'PHPSESSID=sr74f5rrdmk19ogd6fvgra9ole'
      }
    };

    axios(config)
    .then((response) => {
      console.log("response: ",response)
      console.log(JSON.stringify(response.data));
      this.dataLastGrouped = JSON.stringify(response.data);
    })
    .catch((error) => {
      console.log(error);
    });

    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    newAlertEstacion: function(newval, oldval){
      if(newval === null || newval === undefined){
        this.newAlertVariable = null;
      }
      console.log("old: ",oldval," newval: ",newval);
      this.fetchVariables(newval.id);
    },
    // selectedNotif: function(newval, oldval){
    //   console.log("old: ",oldval," newval: ",newval);
    // },
  },
  beforeCreate(){
  },
  created(){
    
  },
  beforeMount(){
    this.fetchAllVariables();
    this.fetchStations();
    this.fetchNotifications();
    this.fetchForecastVars();
  },
  mounted() {
    window.addEventListener('message', e => {
        if (e.data.event === 'location-selected') {
          console.log(e.data);
          this.setLocation(e.data);
        }
    });
    this.fetchAlerts();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);
  },
  beforeDestroy () {
    window.removeEventListener('message')
  },
};
</script>
